.video-block {
    background-color: $color-black;
    padding: 5rem 0 3rem 0;

    .video-wrapper {
        width: 100%;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 990px) {
            height: 200px;
            margin-bottom: 10px;
        }
    }

    h1 {
        color: $color-main;
        text-align: center;
        font-size: 8rem;
        max-width: 500px;
        margin: 0 auto;
        @media screen and (min-width: 990px) {
            margin-top: -4rem;
        }
    }
}