* {
    margin: 0px;
    padding: 0px;
}

body {

    h1, h2, h3 {
        font-family: $title;
        color: $color-main;
    }

    h1 {
        font-size: 48px;
        text-transform: uppercase;
        position: relative;
    }

    .title-element {
        text-shadow: 0px 3px 0px $color-full-white;
    }

    h2 {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    h3 {
        font-size: 32px;
    }

    p {
        font-family: $main-font;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    a {
        text-decoration: none;
        &:hover {
            text-decoration: none;
            transition: $transition;
        }
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .App   {
        background-color: $color-white;

        .page {
            padding-top: 250px;
        }

        .page--home {
            padding-top: 0px;
        }

        .page--prices {
            margin-bottom: 50px;
            padding-bottom: 50px;

            .prices-wrapper {
                width: 50vw;
                margin: 0 auto;

                @media screen and (max-width: 990px) {
                    width: 100%;
                }
            }
        }

        @media screen and (max-width: 990px) {
            .page--about, .page--events, .page--prices, .page--faq {
                padding-top: 0px;
            }
        }
    }
}