.carousel-wrapper {
    .carousel-indicators [aria-current="true"]{
        background-color: $color-main;
    }
    .carousel-inner {
        .carousel-item {
            img {
                max-height: 70vh;
            }
        }
    }
}