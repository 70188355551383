footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;

    .footer-logo {
        max-width: 150px;
        padding: 1rem;
    }

    .socials {
        ul {
            display: flex;
            justify-content: space-between;
            list-style: none;
            text-align: center;
            padding: 0px;
            width: 300px;
            
            a {
                font-family: 'Roboto';
                font-weight: 300;
                text-transform: uppercase;
                color: $color-black;
                
                &:hover {
                    color: $color-main;
                    transition: $transition;
                }
            }
        }
    }
}