.sponsor-banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .sponsor-card {
        margin: 10px;
        width: 100px;
        height: 100px;

        @media screen and (max-width: 990px){
            width: 80px;
            height: 80px;
        }
    }
}