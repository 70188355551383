.breadcrumbs {
    display: flex;
    flex-wrap: wrap;

    @media screen and(max-width: 990px){
        padding-top: 0rem;
    }

    ul {
        margin: 0px;
        display: flex;
        list-style: none;
        padding: 0;

        li {

            text-transform: lowercase;

            &:not(:last-of-type) {

                &::after {
                    content: '>';
                    margin: 20px;
                }
            }

            a {
                color: $color-black;
                padding-bottom: 5px;

                &:hover {
                    border-bottom: solid 1px $color-main;
                }
            }
        }

        .active {
            color: $color-main;
        }
    }
}