header { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    position: relative;
    z-index: 99;

    @media only screen and (min-width: 990px) {
        position: absolute;
        top: 120px;
        left: 0;
        right: 0;
        margin: 0;
    }


    .logo-wrapper {
        max-width: 150px;
        padding: 1rem;
    }

    @media only screen and (max-width: 990px) {
        align-items: center;
    }

    .nav-wrapper {
        width: 100%;
        max-width: 400px;

        .burger, .close-burger {
            top: 130px;
            font-size: 32px;
            z-index: 99;
            display: flex;
            padding: 10px;
            border-radius: 50%;
            background-color: #543284;

        }

        .close-burger {
            background-color: transparent;
            position: fixed;
            color: $color-main;
            cursor: pointer;
            right: 8%;
            @media only screen and (max-width: 990px) {
                right: 12%;
            }
        }

        .burger {
            right: 10%;
            position: fixed;
            color: $color-main;
            cursor: pointer;

        }

        .animate__slideInRight {
            animation-duration: $animation-duration;
        }

        .mobile-navigation {
            position: fixed;
            top: 0px;
            right: 0px;
            padding: 5rem 2.5rem;
            background-color: $color-black;
            height: 100vh;
            z-index: 90;
            text-align: center;

            @media only screen and (min-width: 990px) {
                padding: 5rem 6.5rem;
            }

            ul {
                padding-top: 6rem;
                list-style: none;
                width: 100%;

                li {
                    padding: 1rem 0;

                    a {
                        font-family: $title;
                        color: $color-white;
                        font-size: 24px;
                        font-weight: 700;

                        @media only screen and (min-width: 990px) {
                            font-size: 2.4rem;
                        }

                        &:hover {
                            color: $color-main;
                            transition: $transition;
                        }
                    }
                }
            }
        }

        .desktop-navigation {
            width: 100%;
            display: none;

            @media only screen and (max-width: 990px) {
                display: none;
            }

            ul {
                margin: 0px;
                display: flex;
                justify-content: space-between;
                list-style: none;

                li {
                    padding: 0 1rem;

                    a {
                        color: $color-main;
                        font-size: 24px;
                        font-family: $title;
                        font-weight: 300;
                        padding-bottom: 5px;
                        text-shadow: $shadow;
                        
                        
                        &:hover {
                            color: $color-grey;
                            transition: $transition;
                        }
                    }
                }
            }
        }
    }
}