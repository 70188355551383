// VARIABLES

// COLORS
$color-main: #fed14a;
$color-white: #f9f9f9;
$color-full-white: #fff;
$color-grey: #e2e2e2;
$color-black: #1f1f1f;
$color-black-80: rgba(31, 31, 31, .80);
$color-black-50: rgba(31, 31, 31, .50);
$color-black-20: rgba(31, 31, 31, .20);
$transparant: rgba(31, 31, 31, 0);

// Text
$title: 'Bebas Neue';
$main-font: 'Roboto';
$shadow: 0px 0px 5px #1f1f1f2d; 

// Animatioins
$transition: .3s;
$animation-duration: .8s;