.member-col {
    margin-bottom: 2rem;
    .member-card {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

        img {
            height: 100%;
        }

        .card-overlay {
            background-color: $color-black-50;
            position: absolute;
            transform: translateX(-100%);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            p {
                margin: 20px;
                font-family: $title;
                color: $color-main;
                font-size: 2rem;
            }
        }

        &:hover {
            .card-overlay {
                transform: translateX(0%);
                transition: .5s;
            }
        }
    }
}