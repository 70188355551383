.questions {
    padding-bottom: 48px;

    @media screen and (min-width: 990px) {
        display: flex;
        flex-wrap: wrap;
    }

    .faq-block {
        padding: 48px 24px 0px 24px;
        
        @media screen and (min-width: 990px) {
            width: 50%;
        }
    
    }

}