
.countdown-banner {
    background-color: #543284;
    padding: 1rem 0;
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;

        @media screen and (max-width: 990px){
            flex-direction: column;

        }

        h3 {
            margin: 0;
            font-size: 2.5rem;
        }

        .timer-wrapper {
            display: flex;

            .timer-item {
                padding: 1rem;
                text-align: center;
                
                span {
                    font-size: 2rem;
                    color: $color-main;
                    font-family: $title;

                    @media screen and (max-width: 990px){
                        font-size: 1rem;
                    }
                }

                p {
                    margin: 0;
                    color: $color-white;
                }

            }
        }

        .button-wrapper {
            display: flex;
            color: $color-main;

            &:hover {
                color: $color-white;
                transition: $transition;
            }
              p {
                  font-size: 20px;
                  text-transform: lowercase;
                  font-family: $title;
                  margin: 0 10px 0 0;
                  
              }
            svg {
                font-size: 30px;
            }

        }
    }
}


