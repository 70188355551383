.cta-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px 0;

    a {
        background-color: $color-main;
        color: $color-white;
        border: none;
        outline: none;
        padding: 1rem;
        font-family: 'Bebas Neue';
        font-size: 20px;
        position: relative;

        &::after{
            content: '';
            position: absolute;
            top: 10px;
            right: -10px;
            border: solid 2px $color-main;
            width: 100%;
            height: 100%;

        }

        &:hover {
            color: $color-black;
            transition: $transition;
        }
    }
}