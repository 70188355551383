.image-text-block {
    padding: 2.5rem 0 2rem 0;

    &-bg {
        background-color: $color-black;

        p {
            color: $color-white;
        }
    }

    .row {
        display: flex;

        .image-col {
            max-height: 450px;
            padding-bottom: 2rem;

            .image-wrapper {
                width: 100%;
                height: 100%;
                position: relative;

                img {
                    height: 100%;
                    width: 100%;
                    position: relative;
                    z-index: 5;
    
                }

                @media screen and (min-width: 990px) {
                    
                    &::after{
                        content: '';
                        position: absolute;
                        top: 15px;
                        right: -15px;
                        border: solid 5px $color-main;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
    
        }
        
        .text-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 2rem;
        }
    }

    .image-left {
        flex-direction: row;
    }

    .image-right {
        flex-direction: row-reverse;
    }

}