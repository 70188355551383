.text-block, .text-block-bg {
    padding: 3rem 0;

    h2 {
        font-size: 48px;
    }
}

.text-block-bg {
    background-color: $color-black;
    color: white;
}